.bg-white {
  background-color: var(--color-white) !important;
}

.bg-secondary {
  background-color: var(--color-secondary) !important;
}

.hover-bg-gray-200:hover {
  background-color: var(--color-gray-200) !important;
}

.hover-bg-gray-100:hover {
  background-color: var(--color-gray-100) !important;
}
