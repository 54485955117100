@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url("./background.css");

:root {
  --color-primary-50: #f1faf9;
  --color-primary-100: #e4f6f5;
  --color-primary-200: #85dfd7;
  --color-primary-300: #5dcfc6;
  --color-primary-400: #40bfb4;
  --color-primary-500: #2dada2;
  --color-primary-600: #249c92;
  --color-primary-700: #1c8a81;
  --color-primary-800: #1a7870;
  --color-primary-900: #19605a;

  --color-white: #ffffff;
  --color-light_white: #f0f0f0;

  --color-gray-0: #f8f8f9;
  --color-gray-50: #f7f9fa;
  --color-gray-100: #f1f3f5;
  --color-gray-200: #e6e8eb;
  --color-gray-300: #d2d5d9;
  --color-gray-400: #bec3c7;
  --color-gray-500: #a3a9af;
  --color-gray-600: #848c94;
  --color-gray-700: #5c6670;
  --color-gray-800: #333e48;
  --color-gray-900: #10171c;

  --color-primary: var(--color-primary-400);
  --color-secondary: var(--color-gray-800);

  --color-water: #2f80ed;
  --color-cold: #56ccf2;
  --color-hot: #eb5757;
  --color-gray: #bdbdbd;
  --color-emissions: #1dda25;
  --color-energy: #fbce43;
  --color-waste: #944a0a;
  --color-gas: #828282;

  --color-esg-environment: #139019;
  --color-esg-social: #ff9808;
  --color-esg-governance: #6fbbdb;
  --color-esg-supply-chain: #a13978;

  --color-success: #4caf50;
  --color-warning: #ffb300;
  --color-error: #f44336;
  --color-info: #2196f3;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

html {
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  background-color: var(--color-gray-100);
  height: 100%;
}

body {
  height: 100%;
}

#root {
  height: 100%;
}

p,
h1,
h2,
h3,
h4,
h5 {
  /* margin: 0; */
  text-transform: none;
}

.inter {
  font-family: Inter;
  font-style: normal;
}

.dm-seriff {
  font-family: DM Serif Display;
  font-style: normal;
}

.hover-underline-primary-900:hover {
  text-decoration: underline;
  text-decoration-color: var(--color-primary-800);
}
