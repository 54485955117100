@import url("./modals.css");
@import url("./carousel.css");

:root {
  --rs-primary-50: var(--color-primary-50);
  --rs-primary-100: var(--color-primary-100);
  --rs-primary-200: var(--color-primary-200);
  --rs-primary-300: var(--color-primary-300);
  --rs-primary-400: var(--color-primary-400);
  --rs-primary-500: var(--color-primary-500);
  --rs-primary-600: var(--color-primary-600);
  --rs-primary-700: var(--color-primary-700);
  --rs-primary-800: var(--color-primary-800);
  --rs-primary-900: var(--color-primary-900);

  --rs-gray-0: var(--color-gray-0);
  --rs-gray-50: var(--color-gray-50);
  --rs-gray-100: var(--color-gray-100);
  --rs-gray-200: var(--color-gray-200);
  --rs-gray-300: var(--color-gray-300);
  --rs-gray-400: var(--color-gray-400);
  --rs-gray-500: var(--color-gray-500);
  --rs-gray-600: var(--color-gray-600);
  --rs-gray-700: var(--color-gray-700);
  --rs-gray-800: var(--color-gray-800);
  --rs-gray-900: var(--color-gray-900);

  --rs-input-bg: var(--color-white);

  --rs-divider-border: var(--color-gray-400);
}
